<template>
  <b-sidebar
    :id="id"
    ref="sidebar"
    :visible="isSidebarActive"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >

    <template #default="{ hide }">
      <b-overlay
        :show="isLoading"
        rounded="sm"
        variant="secondary"
        opacity="0.75"
      >
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            v-if="itemId || itemData.id"
            class="mb-0"
          > <span v-t="`modules.bank_accounts.pages.edit`" />: {{itemData.bank ? itemData.bank.name || '' : `#${itemId}`}}</h5>
          <h5
            v-else
            v-t="`modules.bank_accounts.pages.add`"
            class="mb-0"
          />

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />

        </div>

        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Body -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <div
              v-if="banks.length"
              class="mb-2"
            >
              <i-input
                v-model="itemData.bank"
                :errors="validationErrors.bank"
                type="select"
                title="modules.bank_accounts.inputs.bank_id"
                :options="banks"
                :clearable="true"
                label="name"
                required
              />
            </div>

            <div
              v-else
              class="mb-2"
            >
              <i-input
                id="bankName"
                v-model="itemData.bank_name"
                :errors="validationErrors.bank_name"
                type="text"
                title="modules.bank_accounts.inputs.bank_name"
                required
                :translatable="true"
              />
            </div>

            <div class="mb-2">
              <i-input
                id="beneficiary_name"
                v-model="itemData.beneficiary_name"
                :errors="validationErrors.beneficiary_name"
                type="text"
                title="modules.bank_accounts.inputs.beneficiary_name"
                required
              />
            </div>

            <div class="mb-2">
              <i-input
                id="account_number"
                v-model="itemData.account_number"
                :errors="validationErrors.account_number"
                type="text"
                title="modules.bank_accounts.inputs.account_number"
                :required="!itemData.iban"
              />
            </div>

            <div class="mb-2">
              <i-input
                id="iban"
                v-model="itemData.iban"
                :errors="validationErrors.iban"
                type="text"
                title="modules.bank_accounts.inputs.iban"
                :required="!itemData.account_number"
              />
            </div>

            <div>
              <i-input
                v-model="itemData.is_active"
                :errors="validationErrors.is_active"
                type="switch"
                title="inputs.is_active"
                description="inputs.is_active:description"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </i-input>
            </div>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-t="(itemId || itemData.id)?`Edit`:`Add`"
                variant="primary"
                class="mr-2"
                type="submit"
              />
              <b-button
                v-t="`Cancel`"
                variant="outline-secondary"
                @click="hide"
              />
            </div>
          </b-form>
        </validation-observer>

      </b-overlay>

    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BButton, BOverlay,
} from 'bootstrap-vue'
import { onMounted, onUnmounted, ref } from 'vue'
import { ValidationObserver } from 'vee-validate'
import store from '@/store'
import useModelUpdate from '@/views/models/common/useModelUpdate'
import formValidation from '@core/comp-functions/forms/form-validation'
import bankAccountStoreModule from '../bankAccountStoreModule'

export default {
  components: {
    ValidationObserver,
    BSidebar,
    BForm,
    BButton,
    BOverlay,
  },
  directives: {
  },
  emits: ['refetch-data'],
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    id: {
      type: String,
      default: 'sidebar-add-new-bank-account',
      required: false,
    },
    itemId: {
      type: Number,
      default: null,
    },
    banks: {
      type: Array,
      default: Array,
    },
  },
  watch: {
    itemId(newValue) {
      if (newValue) {
        this.isLoading = true
        this.fetchItem(newValue).finally(() => {
          this.isLoading = false
        })
      } else {
        this.resetForm()
      }
    },
    isSidebarActive(newValue) {
      if (newValue) {
        if (!this.itemId) {
          this.resetForm()
        }
      } else {
        this.resetForm()
      }
    },
  },
  mounted() {
    // Set value of refs
    this.refs.value = this.$refs
  },
  setup(props, ctx) {
    const STORE_MODULE_NAME = 'bank_accounts'

    // Register module
    onMounted(() => {
      if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, bankAccountStoreModule)
    })

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    // Data definition
    const refs = ref({})
    const emptyItemData = {
      bank: null,
      bank_name: {
        en: null,
        ar: null,
      },
      beneficiary_name: null,
      account_number: null,
      iban: null,
      accept_payments: null,
      is_active: true,
    }
    const itemData = ref({})
    const isLoading = ref(true)

    const resetItemData = () => {
      itemData.value = JSON.parse(JSON.stringify(emptyItemData))
      isLoading.value = false
      ctx.emit('update:item-id', null)
    }

    resetItemData()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetItemData)

    const { validationErrors, fetch, save } = useModelUpdate(
      STORE_MODULE_NAME,
      refs,
      itemData,
      () => {
        ctx.emit('refetch-data')
        ctx.emit('update:is-sidebar-active', false)
      },
      null,
      null,
      () => {
        // refs.value.value.sidebar.hide()
        itemData.value = JSON.parse(JSON.stringify(emptyItemData))

        ctx.emit('update:is-sidebar-active', false)
      },
    )

    const fetchItem = () => {
      if (itemData.value.id !== props.itemId) {
        itemData.value.id = props.itemId
        return fetch()
      }
      return null
    }

    const onSubmit = () => {
      isLoading.value = true
      save(true,
        () => {
          isLoading.value = false
        }, () => {
          isLoading.value = false
        }, () => {
          isLoading.value = false
        })
    }

    return {
      itemData,
      fetchItem,
      validationErrors,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,

      refs,
      isLoading,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
