<template>
  <b-modal
    id="modal-add-new"
    ref="modal"
    centered
    :title="computedProvider ? (computedProvider.name ? getOfficialMethodName(computedProvider) : $t(`modules.payment_methods.providers.${computedProvider.code}`)) : ''"
    size="lg"
    hide-footer
    ok-title="Accept"
    cancel-title="Cancel"
    body-class="p-0"
    @show="onShow"
  >
    <form-wizard
      ref="refFormObserver"
      :title="null"
      :subtitle="null"
      shape="square"
      :finish-button-text="$t('Save')"
      :back-button-text="$t('Previous')"
      :next-button-text="$t('Next')"
      :start-index="0"
      :color="$themeColors.primary"
      @on-complete="onSubmit"
    >

      <!-- select network tab -->
      <tab-content
        v-if="isCreateMode && provider && (provider.networks.length > 1)"
        :title="$t('modules.payment_methods._create.tabs.select_network').toString()"
        :before-change="validationSelectPaymentNetworkTab"
        :icon="isEditMode ? 'feather icon-file-text' : null"
      >
        <validation-observer
          ref="selectNetworkForm"
          tag="form"
        >
          <select-network-tab
            ref="selectNetworkTab"
            v-model="provider"
            @select-network="val => selectNetwork(val)"
          />
        </validation-observer>
      </tab-content>

      <!-- basic details tab -->
      <tab-content
        :title="$t('modules.payment_methods._create.tabs.basic_settings').toString()"
        :before-change="validationBasicSettingsForm"
        :icon="isEditMode ? 'feather icon-file-text' : null"
      >
        <validation-observer
          ref="basicSettingsRules"
          tag="form"
        >
          <basic-info-tab
            ref="basicSettingsTab"
            v-model="itemData"
            :options="options"
          />
        </validation-observer>
      </tab-content>

      <!-- settings tab -->
      <tab-content
        v-if="itemData.id === 'COD'"
        :title="$t('modules.payment_methods._create.tabs.additional_settings').toString()"
        :icon="isEditMode ? 'feather icon-sliders' : null"
      >
        <validation-observer
          ref="settingsRules"
          tag="form"
        >
          <settings-tab
            v-model="itemData"
          />
        </validation-observer>
      </tab-content>

      <!-- rates tab -->
      <tab-content
        v-if="0"
        :title="$t('modules.payment_methods._create.tabs.payment_rates').toString()"
        :before-change="validationpaymentRatesForm"
        :icon="isEditMode ? 'feather  icon-dollar-sign' : null"
      >
        <validation-observer
          ref="paymentRatesRules"
          tag="form"
        >
          <payment-rates-tab
            v-model="itemData"
          />
        </validation-observer>
      </tab-content>

      <!-- TODO: Future feature -->
      <!-- company information tab -->
      <tab-content
        v-if="0 && computedProvider && computedProvider.replace_with_extra_check"
        title="payment Company Info"
        :before-change="validationCompanyInfoForm"
      >
        <validation-observer
          ref="companyInfoRules"
          tag="form"
        >
          <!--          <company-information-tab />-->
        </validation-observer>
      </tab-content>

      <!-- integration details tab -->
      <tab-content
        v-if="computedProvider && (gatewayConfigInputs.length || networkConfigInputs.length)"
        :title="$t('modules.payment_methods._create.tabs.integration_data').toString()"
        :icon="isEditMode ? 'feather icon-link' : null"
        :before-change="validationIntegrationSettingsForm"
      >
        <validation-observer
          ref="integrationSettingsRules"
          tag="form"
        >
          <integration-settings-tab
            v-model="itemData"
            :provider="computedProvider"
            :gateway-configs="gatewayConfigInputs"
            :network-configs="networkConfigInputs"
          />
        </validation-observer>
      </tab-content>

    </form-wizard>

  </b-modal>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import store from '@/store'
import paymentMethodStoreModule from '@/views/models/payment_methods/paymentMethodStoreModule'
import {
  computed, nextTick, onUnmounted, ref,
} from 'vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import useModelUpdate from '@/views/models/common/useModelUpdate'
import Ripple from 'vue-ripple-directive'
import PaymentRatesTab from '@/views/models/payment_methods/add/PaymentRatesTab.vue'
import BasicInfoTab from '@/views/models/payment_methods/add/BasicInfoTab.vue'
import { toast } from '@core/utils/utils'
import { $themeColors } from '@themeConfig'
import IntegrationSettingsTab from '@/views/models/payment_methods/add/IntegrationSettingsTab.vue'
import SelectNetworkTab from '@/views/models/payment_methods/add/SelectNetworkTab.vue'
import SettingsTab from '@/views/models/payment_methods/add/SettingsTab.vue'
// import CompanyInformationTab from '@/views/models/payment_methods/add/CompanyInformationTab.vue'
// import IntegrationSettingsTab from '@/views/models/payment_methods/add/IntegrationSettingsTab.vue'

export default {
  components: {
    SettingsTab,
    SelectNetworkTab,
    IntegrationSettingsTab,
    ValidationObserver,
    FormWizard,
    TabContent,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    PaymentRatesTab,
    BasicInfoTab,
    // CompanyInformationTab,
    // IntegrationSettingsTab,
  },
  directives: {
    Ripple,
  },
  props: {
    itemId: {
      type: String,
      required: false,
      default: null,
    },
    options: {
      type: Array,
      required: true,
      default: Array,
    },
    provider: {
      type: [Object, null],
      required: false,
      default: Object,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    $themeColors() {
      return $themeColors
    },
    gatewayConfigInputs() {
      const validationRules = this.computedProvider?.validation_rules || {}
      return Object.keys(validationRules).map(ruleId => ({
        id: ruleId,
        rule: validationRules[ruleId],
      }))
    },
    networkConfigInputs() {
      if (!this.paymentNetwork) {
        return []
      }
      const validationRules = this.paymentNetwork.validation_rules || {}
      return Object.keys(validationRules).map(ruleId => ({
        id: ruleId,
        rule: validationRules[ruleId],
      }))
    },
  },
  mounted() {
    // Set value of refs
    this.refs.value = this.$refs

    // if (this.itemData.id) {
    //   this.fetchItemData()
    // }

    // Remount in case of component-reload
    if (!store.hasModule(this.STORE_MODULE_NAME)) store.registerModule(this.STORE_MODULE_NAME, paymentMethodStoreModule)
  },
  methods: {
    validationSelectPaymentNetworkTab() {
      return new Promise((resolve, reject) => {
        this.$refs.selectNetworkForm.validateWithInfo().then(({ success, errors, fields }) => {
          if (success) {
            resolve(true)
          } else {
            const failedFields = Object.keys(fields).filter(key => fields[key].failed)
            if (failedFields.length) {
              toast('warning', this.$t('message.fill_required_fields'))
              this.focusOnTabsOfErrors(failedFields, this.$refs.basicSettingsTab.$refs)
              reject()
            } else {
              resolve(true)
            }
          }
        })
      })
    },
    validationBasicSettingsForm() {
      return new Promise((resolve, reject) => {
        this.$refs.basicSettingsRules.validateWithInfo().then(({ success, errors, fields }) => {
          if (success) {
            resolve(true)
          } else {
            const failedFields = Object.keys(fields).filter(key => fields[key].failed)
            if (failedFields.length) {
              toast('warning', this.$t('message.fill_required_fields'))
              this.focusOnTabsOfErrors(failedFields, this.$refs.basicSettingsTab.$refs)
              reject()
            } else {
              resolve(true)
            }
          }
        })
      })
    },
    validationCompanyInfoForm() {
      return new Promise((resolve, reject) => {
        this.$refs.companyInfoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationIntegrationSettingsForm() {
      return new Promise((resolve, reject) => {
        this.$refs.basicSettingsRules.validateWithInfo().then(({ success, errors, fields }) => {
          if (success) {
            resolve(true)
          } else {
            const failedFields = Object.keys(fields).filter(key => fields[key].failed)
            if (failedFields.length) {
              toast('warning', this.$t('message.fill_required_fields'))
              this.focusOnTabsOfErrors(failedFields, this.$refs.integrationSettingsRules.$refs)
              reject()
            } else {
              resolve(true)
            }
          }
        })
      })
    },
    validationpaymentRatesForm() {
      return new Promise((resolve, reject) => {
        this.$refs.paymentRatesRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    getOfficialMethodName(provider) {
      let { name } = provider
      if (provider.networks.length === 1) {
        name = `${name} - ${provider.networks[0].name}`
      }
      return name
    },
    selectNetwork(networkCode) {
      this.selectedNetwork = networkCode
      this.itemData.payment_network_code = networkCode
    },
  },
  setup(props, ctx) {
    const STORE_MODULE_NAME = 'payment_methods'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, paymentMethodStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    // Data definition
    const refs = ref({})
    const emptyItemData = {
      name: {},
      notes: {},
      courier: {
        code: 'CUSTOM',
        label: null,
      },
      gateway_configs: {},
      network_configs: {},
      is_active: true,
    }

    const itemData = ref({})
    const isLoading = ref(true)
    const rates = ref([])
    const modal = ref()
    const selectedNetwork = ref(null)

    const isCreateMode = computed(() => !props.itemId)
    const isEditMode = computed(() => !isCreateMode.value)
    const computedProvider = computed(() => props.provider || itemData.value?.provider)
    const paymentNetwork = computed(() => {
      const networks = computedProvider.value?.networks || []
      if (selectedNetwork.value) {
        return networks.filter(network => network.code === selectedNetwork.value)?.[0] || null
      }
      return networks?.[0] || null
    })

    const resetItemData = () => {
      itemData.value = JSON.parse(JSON.stringify(emptyItemData))
      isLoading.value = false
      // ctx.emit('update:item-id', null)
    }

    resetItemData()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetItemData)

    const {
      validationErrors, fetch, performSave,
      focusOnTabsOfErrors,
    } = useModelUpdate(
      STORE_MODULE_NAME,
      refs,
      itemData,
      () => {
        modal.value.hide()
        ctx.emit('refetch-data')
        ctx.emit('update:show', false)
        ctx.emit('onSubmit')
      },
      null,
      null,
      () => {
        // refs.value.value.sidebar.hide()
        itemData.value = JSON.parse(JSON.stringify(emptyItemData))

        ctx.emit('update:show', false)
      },
    )

    const fetchItem = () => {
      if ((itemData.value.id !== props.itemId) && props.itemId !== null) {
        itemData.value.id = props.itemId
        return fetch().then(() => refFormObserver.value.activateAll())
      }
      return null
    }

    const onShow = () => {
      resetForm()

      if (isEditMode.value) {
        fetchItem()
      } else {
        itemData.value.provider = props.provider
        itemData.value.payment_gateway_code = props.provider.code
        itemData.value.payment_network_code = paymentNetwork.value?.code
        itemData.value.fixed_fees = 0
        itemData.value.ratio_fees = 0
      }

      nextTick(() => {
        refFormObserver.value.reset()
      })

      // refFormObserver.value.reset()
    }

    const onSubmit = () => {
      isLoading.value = true
      performSave(true,
        () => {
          isLoading.value = false
        }, () => {
          isLoading.value = false
        }, () => {
          isLoading.value = false
        })
    }

    return {
      STORE_MODULE_NAME,
      itemData,
      rates,
      fetchItem,
      validationErrors,
      onShow,
      onSubmit,

      isCreateMode,
      isEditMode,
      computedProvider,
      paymentNetwork,
      selectedNetwork,

      modal,
      refFormObserver,
      getValidationState,
      focusOnTabsOfErrors,
      resetForm,

      refs,
      isLoading,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
[dir] .modal-content .vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked {
  box-shadow: 0 3px 6px 0 rgba(73, 227, 112, 0.4);
}
</style>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
