<template>
  <div>
    <div
      v-if="provider.install_instructions"
      class="pb-2"
    >
      <b-alert
        variant="secondary"
        show
        class="w-100 mb-2"
      >
        <div class="alert-body">
          <p
            v-for="(instruction,k) in provider.install_instructions"
            :key="`instruction-${k}`"
          >
            <span v-html="instruction" />
          </p>
        </div>
      </b-alert>
    </div>
    <b-row
      v-if="gatewayConfigs && gatewayConfigs.length"
      class="pb-2"
    >
      <b-col
        cols="12"
        class="mb-2"
      >
        <h5
          class="mb-0"
        >
          {{ $t('modules.payment_methods._create.tabs.integration_data_gateway_configs_title') }}
        </h5>
        <small class="text-muted">{{ $t('modules.payment_methods._create.tabs.integration_data_gateway_configs_subtitle', {provider: provider.name}) }}</small>
      </b-col>
      <b-col
        v-for="config in gatewayConfigs"
        :key="config.id"
        md="6"
      >
        <i-input
          :id="config.id"
          :ref="config.id"
          v-model="value.gateway_configs[config.id]"
          :errors="validationErrors.name"
          type="text"
          :title="$t(`modules.payment_methods.inputs.$providers.${provider.code}.${config.id}`)"
          placeholder=" "
          description=""
          required
        />
      </b-col>
      <b-col
        v-if="gatewayConfigs.length % 2"
        md="6"
      />
    </b-row>
    <hr v-if="networkConfigs && networkConfigs.length">
    <b-row
      v-if="networkConfigs && networkConfigs.length"
      class="pb-2"
    >
      <b-col
        cols="12"
        class="mb-2"
      >
        <h5
          class="mb-0"
        >
          {{ $t('modules.payment_methods._create.tabs.integration_data_network_configs_title') }}
        </h5>
        <small class="text-muted">{{ $t('modules.payment_methods._create.tabs.integration_data_network_configs_subtitle', {network: network.name}) }}</small>
      </b-col>
      <b-col
        v-for="config in networkConfigs"
        :key="config.id"
        md="6"
      >
        <i-input
          :id="config.id"
          :ref="config.id"
          v-model="value.network_configs[config.id]"
          :errors="validationErrors.name"
          type="text"
          :title="$t(`modules.payment_methods.inputs.$providers.${provider.code}.$networks.${network.code}.${config.id}`)"
          placeholder=" "
          description=""
          required
        />
      </b-col>
      <b-col
        v-if="networkConfigs.length % 2"
        md="6"
      />
    </b-row>
  </div>
</template>

<script>
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { heightTransition } from '@core/mixins/ui/transition'

import {
  BRow,
  BCol, BLink, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BAlert,
    BLink,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    provider: {
      type: Object,
      required: true,
      default: Object,
    },
    value: {
      type: Object,
      required: false,
      default: Object,
    },
    gatewayConfigs: {
      type: Array,
      required: false,
      default: Array,
    },
    networkConfigs: {
      type: Array,
      required: false,
      default: Array,
    },
  },
  computed: {
    network() {
      return this.provider?.networks?.[0] || null
    },
  },
  mounted() {
  },
  setup(props, ctx) {
    return {
      validationErrors: {},
    }
  },
}
</script>
